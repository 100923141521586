import $ from 'jquery';
import {scShipPageManager} from "./shipPage";
window.$ = window.jQuery = $;


$(document).ready(function() {
    'use strict';

    /**
     * Pour les Ponts
     * Ajax qui permet de remplir les champs de la modal edit and update d'un pont
     */
    $(document).on('click', '.open_modal', function () {
        event.preventDefault();
        $.ajax({
            type: 'GET',
            url: '/admin/deck/'+this.id+'/edit',
            data: { tag: 'modal'},
            success: function (data) {
                let txt = '';
                console.log(data);
                $('#id').val(data.model.id);
                $('#name').val(data.model.name);
                $('#code').val(data.model.code);
                $('#img_name').val(data.model.img_name);
                $('#img_title').val(data.model.img_title);
                $('#img_alt').val(data.model.img_alt);
                $('#img_title_es').val(data.model.img_title_es);
                $('#img_alt_es').val(data.model.img_alt_es);
                $('.modal-title').text(data.model.name);
                $('#modal-h1').text(data.model.name);

                //place le <select> navires dans le dom de la modal
                Object.entries(data.ships).forEach(([key, value]) => {
                    let selected = '';
                    selected = (value.id  == data.model.ship_id) ? 'selected' : '';
                    txt += '<option value="' + value.id + '"'+ selected + '  >' + `${value.id}` + '- ' + `${value.name_fr}` + '</option>';
                });
                $('#ship_option').after(txt);

                //place le <select> status dans le dom de la modal
                txt = '';
                for (let i = 0; i <= 1; i++) {
                    let selected = '';
                    selected = (i  == data.model.status) ? 'selected' : '';
                    txt += '<option value="' + i + '"'+ selected + '  >' + i + '</option>';
                }
                $('#status_option').nextAll('option').remove();
                $('#status_option').after(txt);

                //place l'image du pont dans le dom de la modal
                if (data.model.img_name !== '') {
                    $('#img_js').html('<img width="150" src="/img/admin/decks/' + data.model.img_name +  '" />');
                }
            }
        });
    });

    /**
     * POur les cabines
     * Ajax qui permet de remplir les champs de la modal edit and update cabine
     */
    $(document).on('click', '.open_cabin_modal', function (event) {
        event.preventDefault();

        /**
         * recupére un blade avec les descriptifs formatés avec Str::markdown
         * @type {string[]}
         */
        $.ajax({
            type: 'GET',
            url: '/admin/cabin/'+this.id+'/DescFr',
            success: function (data) {
                scShipPageManager.loadCkeditor(data, 'fr');
            }
        });
        $.ajax({
            type: 'GET',
            url: '/admin/cabin/'+this.id+'/DescEs',
            success: function (data) {
                scShipPageManager.loadCkeditor(data, 'es');
            }
        });


        /**
         * Rempli les autres champs de la modal edit and update cabine
         */
        $.ajax({
            type: 'GET',
            url: '/admin/cabin/'+this.id+'/edit',
            data: { tag: 'modal'},
            success: function (data) {
                let txt = '';
                $('#id').val(data.model.id);
                $('#cabin_id').val(data.model.id);
                $('#name_fr').val(data.model.name_fr);
                $('#name_es').val(data.model.name_es);
                $('#cabin_code').val(data.model.cabin_code);
                $('#superficie').val(data.model.superficie);
                $('#balcon_superficie').val(data.model.balcon_superficie);

                $('.modal-title').text('');
                $('.modal-title').text(data.model.name_fr);
                $('#modal-h1').text(data.model.name_fr);

                //place le <select> categories dans le dom de la modal
                Object.entries(data.categories).forEach(([key, value]) => {
                    let selected = '';
                    selected = (value.category_code == data.model.category_code) ? 'selected' : '';
                    txt += '<option value="' + value.category_code + '"'+ selected + '  >' + `${value.category_name}` + '</option>';
                });
                $('#categories_option').after(txt);

                //place le <select> order dans le dom de la modal
                let optionTxt = '';
                for (let i = 0; i <= 100; i++) {
                    let selected = '';
                    selected = (i===data.model._order) ? 'selected' : '';
                    optionTxt += '<option value="' + i + '" ' + selected + '>' + i + '</option>';
                }
                $('#order_option').empty();
                $('#order_option').html(optionTxt);

                //place le <select> status dans le dom de la modal
                txt = '';
                for (let i = 0; i <= 1; i++) {
                    let selected = '';
                    selected = (i  === data.model.status) ? 'selected' : '';
                    txt += '<option value="' + i + '"'+ selected + '  >' + i + '</option>';
                }
                $('#status_option').after(txt);

                //place le select avec les navires associés dans le dom modal
                let dom = '';
                //nettoyage du dom avant de le remplir
                document.querySelectorAll('.ship_option').forEach(e => e.remove());
                Object.entries(data.ships_id).forEach(([key, name]) => {
                    dom += '<div class="ship_option row">';
                    dom += '<div class="col-3 mb-3">';
                    dom += '<select width="50px" name="ship_id" class="form-control custom-select" readonly>';
                    Object.entries(data.ships).forEach(([k, ship]) => {
                        let selected = '';
                        selected = (key  == ship.id) ? 'selected' : '';
                        dom += '<option value="' + ship.id + '"'+ selected + '>' + ship.name_fr + '</option>';
                    });
                    dom += '</select></div></div>';
                });
                $('#ships_option').after(dom);
            }
        });
    });
});
